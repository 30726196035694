import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";
import SimpleReactLightbox, {
	SRLWrapper,
	useLightbox,
} from "simple-react-lightbox";

// Images
import PortStu1 from "../../images/achievements/Student1.jpg";
import PortStu2 from "../../images/achievements/Student2.jpg";
import PortStu3 from "../../images/achievements/Student3.jpg";
import PortStu4 from "../../images/achievements/Student4.png";
import PortStu5 from "../../images/achievements/Student5.JPG";
import PortStu6 from "../../images/achievements/Student6.JPG";
import PortAch1 from "../../images/achievements/Achievement1.jpg";
import PortAch2 from "../../images/achievements/Achievement2.jpg";
import PortAch3 from "../../images/achievements/Achievement3.jpg";
import PortAch4 from "../../images/achievements/Achievement4.jpg";
import PortAch5 from "../../images/achievements/Achievement5.jpg";
import PortAch6 from "../../images/achievements/Achievement6.jpg";
import PortAch7 from "../../images/achievements/Achievement7.jpg";
import PortAch8 from "../../images/achievements/Achievement8.JPG";
import PortAch9 from "../../images/achievements/Achievement9.JPG";
import PortAch10 from "../../images/achievements/Achievement10.png";
import PortAch11 from "../../images/achievements/Achievement11.png";
import PortAch12 from "../../images/news/news8_1.jpg";
import PortAch13 from "../../images/news/news8_2.jpg";
import PortAch14 from "../../images/achievements/Achievement14.png";
import PortDigido1_2022 from "../../images/achievements/Digido2022_1.png";
import PortDigido2_2022 from "../../images/achievements/Digido2022_2.png";
import PortDigido3_2022 from "../../images/achievements/Digido2022_3.png";
import PortDigido4_2022 from "../../images/achievements/Digido2022_4.png";
import PortDigido5_2022 from "../../images/achievements/Digido2022_5.jpg";
import PortDigido6_2022 from "../../images/achievements/Digido2022_6.jpg";
import PortDigido7_2022 from "../../images/achievements/Digido2022_7.jpg";
import PortDigido1_2023 from "../../images/achievements/Digido2023_1.png";
import PortDigido2_2023 from "../../images/achievements/Digido2023_2.png";
import PortDigido3_2023 from "../../images/achievements/Digido2023_3.png";
import PortDigido4_2023 from "../../images/achievements/Digido2023_4.jpg";
import PortDigido5_2023 from "../../images/achievements/Digido2023_5.jpg";
import PortDigido6_2023 from "../../images/achievements/Digido2023_6.jpg";

import PortAIO2024 from "../../images/achievements/AIO2024.png";
import PortAIO1 from "../../images/achievements/AIO1.jpg";
import PortAIO7 from "../../images/achievements/AIO7.jpg";



import PortAwardCeremony1 from "../../images/achievements/AwardCeremony1.jpg";
import PortAwardCeremony2 from "../../images/achievements/AwardCeremony2.jpg";
import PortAwardCeremony3 from "../../images/achievements/AwardCeremony3.jpg";
import PortAwardCeremony4 from "../../images/achievements/AwardCeremony4.jpg";
import PortAwardCeremony5 from "../../images/achievements/AwardCeremony5.jpg";
import PortAwardCeremony6 from "../../images/achievements/AwardCeremony6.jpg";
import PortAwardCeremony7 from "../../images/achievements/AwardCeremony7.jpg";
import PortAwardCeremony8 from "../../images/achievements/AwardCeremony8.jpg";
import PortAwardCeremony9 from "../../images/achievements/AwardCeremony9.jpg";
import PortAwardCeremony10 from "../../images/achievements/AwardCeremony10.jpg";
import PortAwardCeremony11 from "../../images/achievements/AwardCeremony11.jpg";
import PortAwardCeremony12 from "../../images/achievements/AwardCeremony12.jpg";
import PortAwardCeremony13 from "../../images/achievements/AwardCeremony13.jpg";
import PortAwardCeremony14 from "../../images/achievements/AwardCeremony14.jpg";
import PortAwardCeremony15 from "../../images/achievements/AwardCeremony15.jpg";
import PortAwardCeremony16 from "../../images/achievements/AwardCeremony16.jpg";
import PortAwardCeremony17 from "../../images/achievements/AwardCeremony17.jpg";
import PortAwardCeremony18 from "../../images/achievements/AwardCeremony18.jpg";
import PortAwardCeremony19 from "../../images/achievements/AwardCeremony19.jpg";

import PortAwardCeremony21 from "../../images/achievements/AwardCeremony2024_1_5.jpg";
import PortAwardCeremony2024_Speech_1 from "../../images/achievements/AwardCeremony2024_1.jpg";
import PortAwardCeremony2024_Speech_2 from "../../images/achievements/AwardCeremony2024_2.jpg";
import PortAwardCeremony2024_Speech_3 from "../../images/achievements/AwardCeremony2024_3.jpg";
import PortAwardCeremony2024_Speech_4 from "../../images/achievements/AwardCeremony2024_4.jpg";
import PortAwardCeremony2024_Speech_5 from "../../images/achievements/AwardCeremony2024_5.jpg";
import PortAwardCeremony2024_Speech_6 from "../../images/achievements/AwardCeremony2024_6.jpg";

import PortAwardCeremony2024_AC_1 from "../../images/achievements/AwardCeremony2024_7.jpg";
import PortAwardCeremony2024_AC_2 from "../../images/achievements/AwardCeremony2024_8.jpg";
import PortAwardCeremony2024_AC_3 from "../../images/achievements/AwardCeremony2024_9.jpg";
import PortAwardCeremony2024_AC_4 from "../../images/achievements/AwardCeremony2024_10.jpg";
import PortAwardCeremony2024_AC_5 from "../../images/achievements/AwardCeremony2024_11.jpg";	
import PortAwardCeremony2024_AC_6 from "../../images/achievements/AwardCeremony2024_12.jpg";
import PortAwardCeremony2024_AC_7 from "../../images/achievements/AwardCeremony2024_13.jpg";
import PortAwardCeremony2024_AC_8 from "../../images/achievements/AwardCeremony2024_14.jpg";
import PortAwardCeremony2024_AC_9 from "../../images/achievements/AwardCeremony2024_14_1.jpg";
import PortAwardCeremony2024_AC_10 from "../../images/achievements/AwardCeremony2024_14_2.jpg";
import PortAwardCeremony2024_AC_11 from "../../images/achievements/AwardCeremony2024_14_3.jpg";

import PortAwardCeremony2024_STORY_1 from "../../images/achievements/AwardCeremony2024_15.jpg";
import PortAwardCeremony2024_STORY_2 from "../../images/achievements/AwardCeremony2024_16.jpg";
import PortAwardCeremony2024_STORY_3 from "../../images/achievements/AwardCeremony2024_17.jpg";
import PortAwardCeremony2024_STORY_4 from "../../images/achievements/AwardCeremony2024_18.jpg";
import PortAwardCeremony2024_STORY_5 from "../../images/achievements/AwardCeremony2024_19.jpg";
import PortAwardCeremony2024_STORY_6 from "../../images/achievements/AwardCeremony2024_20.jpg";

import PortAwardCeremony2024_LD_1 from "../../images/achievements/AwardCeremony2024_21.jpg";
import PortAwardCeremony2024_LD_2 from "../../images/achievements/AwardCeremony2024_22.jpg";
import PortAwardCeremony2024_LD_3 from "../../images/achievements/AwardCeremony2024_23.jpg";
import PortAwardCeremony2024_LD_4 from "../../images/achievements/AwardCeremony2024_24.jpg";
import PortAwardCeremony2024_LD_5 from "../../images/achievements/AwardCeremony2024_25.jpg";
import PortAwardCeremony2024_LD_6 from "../../images/achievements/AwardCeremony2024_26.jpg";
import PortAwardCeremony2024_LD_7 from "../../images/achievements/AwardCeremony2024_27.jpg";
import PortAwardCeremony2024_LD_8 from "../../images/achievements/AwardCeremony2024_28.jpg";
import PortAwardCeremony2024_LD_9 from "../../images/achievements/AwardCeremony2024_29.jpg";
import PortAwardCeremony2024_LD_10 from "../../images/achievements/AwardCeremony2024_30.jpg";
import PortAwardCeremony2024_LD_11 from "../../images/achievements/AwardCeremony2024_31.png";

import PortGiftedChild1 from "../../images/achievements/GiftedChild1.jpg";
import PortGiftedChild2 from "../../images/achievements/GiftedChild2.jpg";
import PortGiftedChild3 from "../../images/achievements/GiftedChild3.jpg";
import PortGiftedChild4 from "../../images/achievements/GiftedChild4.JPG";
import PortGiftedChild5 from "../../images/achievements/GiftedChild5.JPG";
import PortGiftedChild6 from "../../images/achievements/GiftedChild6.JPG";
import PortGiftedChild7 from "../../images/achievements/GiftedChild7.jpg";
import PortGiftedChild8 from "../../images/achievements/GiftedChild8.jpg";

import PortAIMLTrain1 from "../../images/achievements/AIMLTrain1.jpg";
import PortAIMLTrain2 from "../../images/achievements/AIMLTrain2.jpg";
import PortAIMLTrain3 from "../../images/achievements/AIMLTrain3.jpg";
import PortAIMLTrain4 from "../../images/achievements/AIMLTrain4.jpg";
import PortAIMLTrain5 from "../../images/achievements/AIMLTrain5.jpg";
import PortAIMLTrain6 from "../../images/achievements/AIMLTrain6.jpg";
import PortAIMLTrain7 from "../../images/achievements/AIMLTrain7.png";
import PortAIMLTrain8 from "../../images/achievements/AIMLTrain8.jpg";
import PortAIMLTrain9 from "../../images/achievements/AIMLTrain9.jpg";
import PortAIMLTrain10 from "../../images/achievements/AIMLTrain10.jpg";

import PortAIMLImg_bugar_a1 from "../../images/achievements/bugar_a1.png"
import PortAIMLImg_bugar_a2 from "../../images/achievements/bugar_a2.png"
import PortAIMLImg_bugar_a3 from "../../images/achievements/bugar_a3.png"
import PortAIMLImg_bugar_a4 from "../../images/achievements/bugar_a4.png"
import PortAIMLImg_bugar_c1 from "../../images/achievements/bugar_c1.png"
import PortAIMLImg_bugar_c4 from "../../images/achievements/bugar_c4.png"
import PortAIMLImg_bugar_c5 from "../../images/achievements/bugar_c5.png"
import PortAIMLImg_bugar_c6 from "../../images/achievements/bugar_c6.png"
import PortAIMLImg_bugar_comp1 from "../../images/achievements/bugar_comp1.png"
import PortAIMLImg_bugar_comp2 from "../../images/achievements/bugar_comp2.png"
import PortAIMLImg_bugar_comp3 from "../../images/achievements/bugar_comp3.png"
import PortAIMLImg_bugar_comp4 from "../../images/achievements/bugar_comp4.png"
import PortAIMLImg_bugar_comp5 from "../../images/achievements/bugar_comp5.png"
import PortAIMLImg_bugar_comp6 from "../../images/achievements/bugar_comp6.png"
import PortAIMLImg_bugar_t1 from "../../images/achievements/bugar_t1.png"
import PortAIMLImg_bugar_t3 from "../../images/achievements/bugar_t3.png"
import PortAIMLImg_bugar_t4 from "../../images/achievements/bugar_t4.png"

import { withTranslation } from "react-i18next";

import i18next from "i18next";


// Magnific Anchor
const MagnificAnchor = (props) => {
	const { openLightbox } = useLightbox();

	return (
		<Link
			to={"#"}
			onClick={() => openLightbox(props.imageToOpen)}
			className="magnific-anchor"
		>
			<i className="ti-search"></i>
		</Link>
	);
};

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#f7b205",
		slideAnimationType: "slide",
	},
	buttons: {
		backgroundColor: "#f7b205",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	},
};

const FilterList = ({ dataFilter, defaultTag, activeFilter }) => {
	return (
		<li
			className={`${activeFilter ? "btn active" : "btn"}`}
			onClick={() => defaultTag(dataFilter)}
		>
			<Link to={"#"}>{dataFilter}</Link>
		</li>
	);
};
const TagDescriptionList = [
	{
		tag: "Achievements_Photos_Achievement_AIO",
		title: "Achievement_AIO_Title",
		description: "Achievement_AIO_description",
	},
	{
		tag: "Achievements_Photos_Achievement_YICT",
		title: "Achievement_YoungICT_Title",
		description: "Achievement_YoungICT_description",
	},
	{
		tag: "Achievements_Photos_Achievement_ICAS",
		title: "Achievement_ICAS_Title",
		description: "Achievement_ICAS_description",
	},
	{
		tag: "Achievements_Photos_Achievement_FLL",
		title: "Achievement_Lego_Title",
		description: "Achievement_Lego_description_fll",
	},
	{
		tag: "Achievements_Photos_Achievement_ROBOCUP",
		description: "Achievement_Lego_description_robocup",
	},
	{
		tag: "Achievements_Photos_Achievement_Bebras",
		title: "Achievement_Bebras_Title",
		description: "Achievement_Bebras_description",
	},
	{
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Community_Digido_Title_2022",
		description: "Community_Digido_2022_description",
	},
	{
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Community_Digido_Title_2023",
		description: "Community_Digido_2023_description",
	},
	{
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Community_GiftedChild_Title",
		description: "Community_GiftedChild_description",
	},
	{
		tag: "Achievements_Photos_ClassEngagement",
		title: "ClassEngagement_Title",
		description: "ClassEngagement_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award_Ceremony_2023_Title",
		description: "Award_Ceremony_2023_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award_Ceremony_2024_Title",
		description: "Award_Ceremony_2024_Speech_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2024_PanelDiscussion",
		description: "Award_Ceremony_2024_PanelDiscussion_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		description: "Award_Ceremony_2024_AwardCeremony_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		description: "Award_Ceremony_2024_Story_description",
	},
	{
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		description: "Award_Ceremony_2024_LuckyDraw_description",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "Achievements_Photos_IOAI_2024_Train_AIML_Title",
		description: "Achievements_Photos_IOAI_2024_Train_AIML_description",
		link: "/news-7",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Camp_AIML",
		title: "Achievements_Photos_IOAI_2024_Camp_Title",
		description: "Achievements_Photos_IOAI_2024_Camp_description",
		link: "/news-7",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Team_AIML",
		title: "Achievements_Photos_IOAI_2024_bugar_Title",
		description: "Bugar_Team_DESC",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
		title: "",
		description: "Bugar_Activity_DESC",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",
		title: "",
		description: "Bugar_Conference_Workshop_DESC",
	},
	{
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "",
		description: "Bugar_Competiton_DESC",
	},
];

const content = [
	// Student images
	{
		thumb: PortStu2,
		LightImg: PortStu2,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Certificate of Level 2",
	},
	{
		thumb: PortStu3,
		LightImg: PortStu3,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Certificate of Level 3",
	},
	{
		thumb: PortStu4,
		LightImg: PortStu4,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu5,
		LightImg: PortStu5,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu6,
		LightImg: PortStu6,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Students in Class",
	},
	{
		thumb: PortStu1,
		LightImg: PortStu1,
		tag: "Achievements_Photos_ClassEngagement",
		title: "Class of Level 1",
	},
	// AIO images
	{
		thumb: PortAIO2024,
		LightImg: PortAIO2024,
		tag: "Achievements_Photos_Achievement_AIO",
		title: "AIO 2024 Medals",
	},
	{
		thumb: PortAIO7,
		LightImg: PortAIO7,
		tag: "Achievements_Photos_Achievement_AIO",
		title: "AIO 2023 Medals",
	},
	{
		thumb: PortAIO1,
		LightImg: PortAIO1,
		tag: "Achievements_Photos_Achievement_AIO",
		title: "AIO 2023 Gold Medal",
	},

	// Achievement images
	{
		thumb: PortAch1,
		LightImg: PortAch1,
		tag: "Achievements_Photos_Achievement_YICT",
		title: "Young ICT South Australia 1st Place",
	},
	{
		thumb: PortAch2,
		LightImg: PortAch2,
		tag: "Achievements_Photos_Achievement_ICAS",
		title: "ICAS Year 4 Digital Technologies South Australia 2020 Medal",
	},
	{
		thumb: PortAch3,
		LightImg: PortAch3,
		tag: "Achievements_Photos_Achievement_ICAS",
		title: "ICAS Year 4 Digital Technologies South Australia 2020 High Distinction",
	},
	{
		thumb: PortAch4,
		LightImg: PortAch4,
		tag: "Achievements_Photos_Achievement_ICAS",
		title: "ICAS Year 5 Digital Technologies South Australia 2021 High Distinction",
	},
	{
		thumb: PortAch6,
		LightImg: PortAch6,
		tag: "Achievements_Photos_Achievement_Bebras",
		title: "Grade 6 Round 1 of the 2021 Bebras Australia Computational Thinking Challenge High Distinction",
	},
	{
		thumb: PortAch7,
		LightImg: PortAch7,
		tag: "Achievements_Photos_Achievement_Bebras",
		title: "Grade 6 Round 2 of the 2021 Bebras Australia Computational Thinking Challenge High Distinction",
	},
	{
		thumb: PortAch8,
		LightImg: PortAch8,
		tag: "Achievements_Photos_Achievement_Bebras",
		title: "Bebras Australia Computational Thinking Challenge 2023 High Distinction",
	},
	{
		thumb: PortAch9,
		LightImg: PortAch9,
		tag: "Achievements_Photos_Achievement_Bebras",
		title: "Bebras Australia Computational Thinking Challenge 2023 High Distinction",
	},
	{
		thumb: PortAch10,
		LightImg: PortAch10,
		tag: "Achievements_Photos_Achievement_ROBOCUP",
		title: "Robot Cup 1",
	},
	{
		thumb: PortAch11,
		LightImg: PortAch11,
		tag: "Achievements_Photos_Achievement_ROBOCUP",
		title: "Robot Cup 2",
	},
	{
		thumb: PortAch12,
		LightImg: PortAch12,
		tag: "Achievements_Photos_Achievement_ROBOCUP",
		title: "Robocup National 2024",
	},
	{
		thumb: PortAch13,
		LightImg: PortAch13,
		tag: "Achievements_Photos_Achievement_ROBOCUP",
		title: "Robocup National 2024",
	},
	{
		thumb: PortAch5,
		LightImg: PortAch5,
		tag: "Achievements_Photos_Achievement_FLL",
		title: "2022 First Lego League National Champion South Australia",
	},
	{
		thumb: PortAch14,
		LightImg: PortAch14,
		tag: "Achievements_Photos_Achievement_FLL",
		title: "2024 First Lego League Awards",
	},
	// Digido images 2022
	{
		thumb: PortDigido1_2022,
		LightImg: PortDigido1_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 1 2022",
	},
	{
		thumb: PortDigido2_2022,
		LightImg: PortDigido2_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 2 2022",
	},
	{
		thumb: PortDigido3_2022,
		LightImg: PortDigido3_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 3 2022",
	},
	{
		thumb: PortDigido4_2022,
		LightImg: PortDigido4_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 4 2022",
	},
	{
		thumb: PortDigido5_2022,
		LightImg: PortDigido5_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 5 2022",
	},
	{
		thumb: PortDigido6_2022,
		LightImg: PortDigido6_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 6 2022",
	},
	{
		thumb: PortDigido7_2022,
		LightImg: PortDigido7_2022,
		tag: "Achievements_Photos_Community_Digido_2022",
		title: "Digido 7 2022",
	},
	// Digido images 2023
	{
		thumb: PortDigido1_2023,
		LightImg: PortDigido1_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 1 2023",
	},
	{
		thumb: PortDigido2_2023,
		LightImg: PortDigido2_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 2 2023",
	},
	{
		thumb: PortDigido3_2023,
		LightImg: PortDigido3_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 3 2023",
	},
	{
		thumb: PortDigido4_2023,
		LightImg: PortDigido4_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 4 2023",
	},
	{
		thumb: PortDigido5_2023,
		LightImg: PortDigido5_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 5 2023",
	},
	{
		thumb: PortDigido6_2023,
		LightImg: PortDigido6_2023,
		tag: "Achievements_Photos_Community_Digido_2023",
		title: "Digido 6 2023",
	},
	// Gifted Child images
	{
		thumb: PortGiftedChild1,
		LightImg: PortGiftedChild1,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 1",
	},
	{
		thumb: PortGiftedChild2,
		LightImg: PortGiftedChild2,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 2",
	},
	{
		thumb: PortGiftedChild3,
		LightImg: PortGiftedChild3,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 3",
	},
	{
		thumb: PortGiftedChild4,
		LightImg: PortGiftedChild4,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 4",
	},
	{
		thumb: PortGiftedChild5,
		LightImg: PortGiftedChild5,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 5",
	},
	{
		thumb: PortGiftedChild6,
		LightImg: PortGiftedChild6,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 6",
	},
	{
		thumb: PortGiftedChild7,
		LightImg: PortGiftedChild7,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 7",
	},
	{
		thumb: PortGiftedChild8,
		LightImg: PortGiftedChild8,
		tag: "Achievements_Photos_Community_GiftedChild",
		title: "Gifted Child 8",
	},
	// Award Ceremony images
	{
		thumb: PortAwardCeremony1,
		LightImg: PortAwardCeremony1,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 1",
	},
	{
		thumb: PortAwardCeremony2,
		LightImg: PortAwardCeremony2,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 2",
	},
	{
		thumb: PortAwardCeremony3,
		LightImg: PortAwardCeremony3,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 3",
	},
	{
		thumb: PortAwardCeremony4,
		LightImg: PortAwardCeremony4,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 4",
	},
	{
		thumb: PortAwardCeremony5,
		LightImg: PortAwardCeremony5,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 5",
	},
	{
		thumb: PortAwardCeremony6,
		LightImg: PortAwardCeremony6,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 6",
	},
	{
		thumb: PortAwardCeremony7,
		LightImg: PortAwardCeremony7,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 7",
	},
	{
		thumb: PortAwardCeremony8,
		LightImg: PortAwardCeremony8,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 8",
	},
	{
		thumb: PortAwardCeremony9,
		LightImg: PortAwardCeremony9,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 9",
	},
	{
		thumb: PortAwardCeremony10,
		LightImg: PortAwardCeremony10,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 10",
	},
	{
		thumb: PortAwardCeremony11,
		LightImg: PortAwardCeremony11,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 11",
	},
	{
		thumb: PortAwardCeremony12,
		LightImg: PortAwardCeremony12,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 12",
	},
	{
		thumb: PortAwardCeremony13,
		LightImg: PortAwardCeremony13,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 13",
	},
	{
		thumb: PortAwardCeremony14,
		LightImg: PortAwardCeremony14,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 14",
	},
	{
		thumb: PortAwardCeremony15,
		LightImg: PortAwardCeremony15,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 15",
	},
	{
		thumb: PortAwardCeremony16,
		LightImg: PortAwardCeremony16,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 16",
	},
	{
		thumb: PortAwardCeremony17,
		LightImg: PortAwardCeremony17,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 17",
	},
	{
		thumb: PortAwardCeremony18,
		LightImg: PortAwardCeremony18,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 18",
	},
	{
		thumb: PortAwardCeremony19,
		LightImg: PortAwardCeremony19,
		tag: "Achievements_Photos_Award_Ceremony_2023",
		title: "Award Ceremony 19",
	},
	// PART 1: SPEECH
	{
		thumb: PortAwardCeremony2024_Speech_1,
		LightImg: PortAwardCeremony2024_Speech_1,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	{
		thumb: PortAwardCeremony2024_Speech_2,
		LightImg: PortAwardCeremony2024_Speech_2,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	{
		thumb: PortAwardCeremony2024_Speech_3,
		LightImg: PortAwardCeremony2024_Speech_3,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	{
		thumb: PortAwardCeremony2024_Speech_4,
		LightImg: PortAwardCeremony2024_Speech_4,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	{
		thumb: PortAwardCeremony2024_Speech_5,
		LightImg: PortAwardCeremony2024_Speech_5,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	{
		thumb: PortAwardCeremony2024_Speech_6,
		LightImg: PortAwardCeremony2024_Speech_6,
		tag: "Achievements_Photos_Award_Ceremony_2024_Speech",
		title: "Award Ceremony 2024 Inspiring Speeches",
	},
	// PART 2: PANEL DISCUSSION
	{
		thumb: PortAwardCeremony21,
		LightImg: PortAwardCeremony21,
		tag: "Achievements_Photos_Award_Ceremony_2024_PanelDiscussion",
		title: "Award Ceremony 2024 Panel Discussion",
	},
	// PART 3: AC
	{
		thumb: PortAwardCeremony2024_AC_1,
		LightImg: PortAwardCeremony2024_AC_1,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_2,
		LightImg: PortAwardCeremony2024_AC_2,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_3,
		LightImg: PortAwardCeremony2024_AC_3,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_4,
		LightImg: PortAwardCeremony2024_AC_4,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_5,
		LightImg: PortAwardCeremony2024_AC_5,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_6,
		LightImg: PortAwardCeremony2024_AC_6,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_7,
		LightImg: PortAwardCeremony2024_AC_7,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_8,
		LightImg: PortAwardCeremony2024_AC_8,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_9,
		LightImg: PortAwardCeremony2024_AC_9,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_10,
		LightImg: PortAwardCeremony2024_AC_10,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	{
		thumb: PortAwardCeremony2024_AC_11,
		LightImg: PortAwardCeremony2024_AC_11,
		tag: "Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
		title: "Award Ceremony 2024 Award Ceremony",
	},
	// PART 4: STORY
	{
		thumb: PortAwardCeremony2024_STORY_1,
		LightImg: PortAwardCeremony2024_STORY_1,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	{
		thumb: PortAwardCeremony2024_STORY_2,
		LightImg: PortAwardCeremony2024_STORY_2,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	{
		thumb: PortAwardCeremony2024_STORY_3,
		LightImg: PortAwardCeremony2024_STORY_3,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	{
		thumb: PortAwardCeremony2024_STORY_4,
		LightImg: PortAwardCeremony2024_STORY_4,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	{
		thumb: PortAwardCeremony2024_STORY_5,
		LightImg: PortAwardCeremony2024_STORY_5,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	{
		thumb: PortAwardCeremony2024_STORY_6,
		LightImg: PortAwardCeremony2024_STORY_6,
		tag: "Achievements_Photos_Award_Ceremony_2024_Story",
		title: "Award Ceremony 2024 Inspiring Stories",
	},
	// PART 5: LUCKY DRAW
	{
		thumb: PortAwardCeremony2024_LD_1,
		LightImg: PortAwardCeremony2024_LD_1,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_2,
		LightImg: PortAwardCeremony2024_LD_2,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_3,
		LightImg: PortAwardCeremony2024_LD_3,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_4,
		LightImg: PortAwardCeremony2024_LD_4,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_5,
		LightImg: PortAwardCeremony2024_LD_5,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_6,
		LightImg: PortAwardCeremony2024_LD_6,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_7,
		LightImg: PortAwardCeremony2024_LD_7,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_8,
		LightImg: PortAwardCeremony2024_LD_8,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_9,
		LightImg: PortAwardCeremony2024_LD_9,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_10,
		LightImg: PortAwardCeremony2024_LD_10,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },
	  {
		thumb: PortAwardCeremony2024_LD_11,
		LightImg: PortAwardCeremony2024_LD_11,
		tag: "Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
		title: "Award Ceremony 2024 Lucky Draw and Foods",
	  },

	// AIML Train images
	{
		thumb: PortAIMLTrain1,
		LightImg: PortAIMLTrain1,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 1",
	},
	{
		thumb: PortAIMLTrain2,
		LightImg: PortAIMLTrain2,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 2",
	},
	{
		thumb: PortAIMLTrain3,
		LightImg: PortAIMLTrain3,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 3",
	},
	{
		thumb: PortAIMLTrain4,
		LightImg: PortAIMLTrain4,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 4",
	},
	{
		thumb: PortAIMLTrain5,
		LightImg: PortAIMLTrain5,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 5",
	},
	{
		thumb: PortAIMLTrain6,
		LightImg: PortAIMLTrain6,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 6",
	},
	{
		thumb: PortAIMLTrain7,
		LightImg: PortAIMLTrain7,
		tag: "Achievements_Photos_IOAI_2024_Train_AIML",
		title: "IOAI 2024 National Train Camp with AIML 7",
	},
	{
		thumb: PortAIMLTrain8,
		LightImg: PortAIMLTrain8,
		tag: "Achievements_Photos_IOAI_2024_Camp_AIML",
		title: "IOAI 2024 National Train Camp with AIML 8",
	},
	{
		thumb: PortAIMLTrain9,
		LightImg: PortAIMLTrain9,
		tag: "Achievements_Photos_IOAI_2024_Camp_AIML",
		title: "IOAI 2024 National Train Camp with AIML 9",
	},
	{
		thumb: PortAIMLTrain10,
		LightImg: PortAIMLTrain10,
		tag: "Achievements_Photos_IOAI_2024_Camp_AIML",
		title: "IOAI 2024 National Train Camp with AIML 10",
	},
	{
		thumb: PortAIMLImg_bugar_t1,
		LightImg: PortAIMLImg_bugar_t1,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Team_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_t3,
		LightImg: PortAIMLImg_bugar_t3,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Team_AIML",
		title: "IOAI 2024 Bulgaria 3",
	},
	{
		thumb: PortAIMLImg_bugar_t4,
		LightImg: PortAIMLImg_bugar_t4,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Team_AIML",
		title: "IOAI 2024 Bulgaria 4",
	},
	{
		thumb: PortAIMLImg_bugar_comp1,
		LightImg: PortAIMLImg_bugar_comp1,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_comp2,
		LightImg: PortAIMLImg_bugar_comp2,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_comp3,
		LightImg: PortAIMLImg_bugar_comp3,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_comp4,
		LightImg: PortAIMLImg_bugar_comp4,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_comp5,
		LightImg: PortAIMLImg_bugar_comp5,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_comp6,
		LightImg: PortAIMLImg_bugar_comp6,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_a1,
		LightImg: PortAIMLImg_bugar_a1,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_a2,
		LightImg: PortAIMLImg_bugar_a2,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_a3,
		LightImg: PortAIMLImg_bugar_a3,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_a4,
		LightImg: PortAIMLImg_bugar_a4,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_c1,
		LightImg: PortAIMLImg_bugar_c1,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_c4,
		LightImg: PortAIMLImg_bugar_c4,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_c5,
		LightImg: PortAIMLImg_bugar_c5,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},
	{
		thumb: PortAIMLImg_bugar_c6,
		LightImg: PortAIMLImg_bugar_c6,
		tag: "Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",
		title: "IOAI 2024 Bulgaria 1",
	},

];
// group tags together
const tags_achievement = [
	"Achievements_Photos_Achievement_AIO",
	"Achievements_Photos_Achievement_YICT",
	"Achievements_Photos_Achievement_ICAS",
	"Achievements_Photos_Achievement_FLL",
	"Achievements_Photos_Achievement_ROBOCUP",
	"Achievements_Photos_Achievement_Bebras"
];
const tags_community = [
	"Achievements_Photos_Community_Digido_2022",
	"Achievements_Photos_Community_Digido_2023",
	"Achievements_Photos_Community_GiftedChild"
];
const tags_award = [
	"Achievements_Photos_Award_Ceremony_2023",
	"Achievements_Photos_Award_Ceremony_2024_Speech",
	"Achievements_Photos_Award_Ceremony_2024_PanelDiscussion",
	"Achievements_Photos_Award_Ceremony_2024_AwardCeremony",
	"Achievements_Photos_Award_Ceremony_2024_Story",
	"Achievements_Photos_Award_Ceremony_2024_LuckyDraw",
];
const tags_classEngagement = [
	"Achievements_Photos_ClassEngagement"
];
const tags_IOAI = [
	"Achievements_Photos_IOAI_2024_Train_AIML",
	"Achievements_Photos_IOAI_2024_Camp_AIML",
	"Achievements_Photos_IOAI_2024_Bulgaria_Team_AIML",
	"Achievements_Photos_IOAI_2024_Bulgaria_Competition_AIML",
	"Achievements_Photos_IOAI_2024_Bulgaria_Activity_AIML",
	"Achievements_Photos_IOAI_2024_Bulgaria_Conference_Workshop_AIML",

];
function MasonryContent() {
	const [filteredImages, setFilteredImages] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(i18next.t("Achievements_Photos_ClassEngagement"));

	useEffect(() => {
		let tagsToFilter = [];

		if (selectedCategory === i18next.t("Achievements_Photos_Achievements")) {
			tagsToFilter = tags_achievement;
		} else if (selectedCategory === i18next.t("Achievements_Photos_Community")) {
			tagsToFilter = tags_community;
		} else if (selectedCategory === i18next.t("Achievements_Photos_Award_Ceremony")) {
			tagsToFilter = tags_award;
		} else if (selectedCategory === i18next.t("Achievements_Photos_ClassEngagement")) {
			tagsToFilter = tags_classEngagement;
		} else if (selectedCategory === i18next.t("Achievements_Photos_IOAI_2024")) {
			tagsToFilter = tags_IOAI;
		}

		setFilteredImages(
			content.filter((image) =>
				tagsToFilter.some((tag) => image.tag.includes(tag))
			)
		);
	}, [selectedCategory]);

	const allTags = [...tags_achievement, ...tags_community, ...tags_award, ...tags_classEngagement, ...tags_IOAI];

	return (
		<>
			<div className="feature-filters clearfix center m-b40">
				<ul className="filters" data-toggle="buttons">
					<FilterList
						dataFilter={i18next.t("Achievements_Photos_ClassEngagement")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Achievements_Photos_ClassEngagement")}
					/>
					<FilterList
						dataFilter={i18next.t("Achievements_Photos_Achievements")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Achievements_Photos_Achievements")}
					/>
					<FilterList
						dataFilter={i18next.t("Achievements_Photos_Community")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Achievements_Photos_Community")}
					/>
					<FilterList
						dataFilter={i18next.t("Achievements_Photos_Award_Ceremony")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Achievements_Photos_Award_Ceremony")}
					/>
					<FilterList
						dataFilter={i18next.t("Achievements_Photos_IOAI_2024")}
						defaultTag={setSelectedCategory}
						activeFilter={selectedCategory === i18next.t("Achievements_Photos_IOAI_2024")}
					/>
				</ul>
			</div>

			<div>
				{allTags.map((tag) => {
					const imagesForTag = filteredImages.filter((image) => image.tag.includes(tag));

					// Find titles and descriptions
					const tagData = TagDescriptionList.find(
						(item) => item.tag === tag
					);
					const tagTitle = tagData?.title;
					const tagDescription = tagData?.description;
					const tagLink = tagData?.link;

					if (imagesForTag.length === 0) {
						return null;
					}
					return (
						<div key={tag}>
							<h3>{i18next.t(tagTitle)}</h3>
							<p>
								{i18next.t(tagDescription)}
								{tagLink && (
									<>
										<a href={tagLink} target="_blank" rel="noopener noreferrer">
											here.
										</a>
									</>
								)}
							</p>
							<SimpleReactLightbox>
								<SRLWrapper options={options}>
									<Masonry className="ttr-gallery-listing">
										{imagesForTag.map((item, index) => (
											<div
												className="action-card col-lg-3 col-md-4 col-sm-6"
												key={index}
											>
												<div className="ttr-box achievements-bx">
													<div className="ttr-media media-ov2 media-effect">
														<img alt="" src={item.thumb} />
														<div className="ov-box">
															<div className="overlay-icon align-m">
																<MagnificAnchor imageToOpen={index} />
															</div>
															<div className="achievements-info-bx">
																<h4>{item.title}</h4>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</Masonry>
								</SRLWrapper>
							</SimpleReactLightbox>
						</div>
					);
				})}
			</div>
		</>
	);
}



class achievementsContent extends Component {
	componentDidMount() {

	}
	render() {
		return (
			<>
				<MasonryContent />
			</>
		);
	}
}

export default withTranslation()(achievementsContent);