import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header/header1";
import bannerImg from "../../images/banner/banner2.jpg";
import { withTranslation } from "react-i18next";

import { Card, Col, Row } from 'antd';
import { Divider } from 'antd';

import eligibilityCriteriaImg from "../../images/ioai/eligibilityCriteria.png";
import submitImg from "../../images/ioai/submit.png";
import peopleImg from "../../images/ioai/people.png";

class IOAIApplication extends Component {
	componentDidMount() {
		
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<Header />
				<div className="page-content">
					<div className="content-block" id="content-area">
						<div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">{t("IOAI_Application_Title")}</h1>
								</div>
							</div>
						</div>
						<div className="breadcrumb-row">
							<div className="container">
								<ul className="list-inline">
									<li>
										<Link to="/">{t("IOAI_Application_LinkHome")}</Link>
									</li>
									<li>{t("IOAI_Application_LinkIOAI")}</li>
								</ul>
							</div>
						</div>

						<div className="section-area section-sp1">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-12 heading-bx style1 text-center">
										<h2 className="title-head">{t("IOAI_Registration_Title_Sub1")}</h2>
									</div>
									<div className="col-md-12 text-left" style={{ paddingLeft: '100px', paddingRight: '100px', }}>
										<p>
											{t("IOAI_Registration_Content1")}
											<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
												<b>
													{t("IOAI_Registration_Content2")}
												</b>
											</a>
											{t("IOAI_Registration_Content3")}
										</p>
									</div>
									<Divider />
								</div>
							</div>
						</div>

						<Row justify="center" align="middle">
							<Col md={10} xs={24}>
								<div>
									<h3 style={{ marginBottom: '30px' }} align="middle">{t("IOAI_Registration_Card_1_Title")}</h3>
									<Card style={{ width: '100%' }}>
										<Card type="inner" title={<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px', minWidth: '150px', color: '#003366' }}>{t("IOAI_Registration_Card_1_inner_1_Title")}</h5>} style={{ marginBottom: '-30px' }}>
											<p style={{ fontSize: '16px', lineHeight: '1.6' }}>{t("IOAI_Registration_Card_1_inner_1_Content")}</p>
										</Card>

										<Card type="inner" title={<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px', minWidth: '150px', color: '#003366' }}>{t("IOAI_Registration_Card_1_inner_2_Title")}</h5>} style={{ marginBottom: '-30px' }}>
											<p style={{ fontSize: '16px', lineHeight: '1.6' }}>{t("IOAI_Registration_Card_1_inner_2_Content")}</p>
										</Card>

										<Card type="inner" title={<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px', minWidth: '150px', color: '#003366' }}>{t("IOAI_Registration_Card_1_inner_3_Title")}</h5>}>
											<p style={{ fontSize: '18px', lineHeight: '1.6' }}>{t("IOAI_Registration_Card_1_inner_3_Content")}</p>

											<ul style={{ paddingLeft: 20, fontSize: 16, lineHeight: '1.6' }}>
												<li style={{ marginBottom: 12 }}>
													<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Registration_Card_1_inner_3_Content_List1")}</p>
												</li>

												<li style={{ marginBottom: 12 }}>
													<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Registration_Card_1_inner_3_Content_List2")}</p>
												</li>

												<li style={{ marginBottom: 12 }}>
													<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Registration_Card_1_inner_3_Content_List3")}</p>
												</li>

												<li style={{ marginBottom: 12 }}>
													<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Registration_Card_1_inner_3_Content_List4")}</p>
												</li>

												<p style={{ color: "#333333", fontStyle: 'italic', textDecoration: "none", display: "block", marginTop: 30, marginBottom: 8 }}>{t("IOAI_Registration_Card_1_inner_3_Content_List5")}</p>
											</ul>
										</Card>
									</Card>
								</div>
							</Col>

							<Col md={10} xs={24} align="center">
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={eligibilityCriteriaImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
						</Row>


						<Row justify="center" align="middle">
							<Col md={10} xs={24} align="center">
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={submitImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
							<Col md={10} xs={24}>
								<Card title={<h3 style={{ fontWeight: 'bold', marginBottom: 20 }} align="middle">{t("IOAI_Registration_Card_2_Title")}</h3>} bordered={false}>
									<Card style={{ width: '100%' }} type="inner" title={<h5>{t("IOAI_Registration_Card_2_inner_1_Title")}</h5>}>
										<p style={{ fontSize: '18px', lineHeight: '1.6' }}>{t("IOAI_Registration_Card_2_inner_1_Content_1")}<b><a rel="noopener" href="mailto:admin@louiscelinetech.com.au" style={{ textDecoration: "underline" }}>{t("IOAI_Registration_Card_2_inner_1_Content_2")}</a></b>{t("IOAI_Registration_Card_2_inner_1_Content_3")}</p>
										<ol style={{ paddingLeft: 20, fontSize: 16, lineHeight: '1.6' }}>
											<li style={{ marginBottom: 12 }}>
												<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 24 }}>{t("IOAI_Registration_Card_2_inner_1_Content_List1")}</p>

											</li>
											<li style={{ marginBottom: 12 }}>
												<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Registration_Card_2_inner_1_Content_List2")}</p>
											</li>
										</ol>
										<a rel="noopener noreferrer" href="/static/pdf/IOAI_Application_Form.pdf" target="_blank" style={{ textDecoration: "underline" }}>
											<b>
												{t("IOAI_Registration_Card_2_inner_1_Content_Download")}
											</b>
										</a>
									</Card>
								</Card>
							</Col>
						</Row>

						<Row justify="center" align="middle">
							<Col md={10} xs={24}>
								<Card title={<h3 style={{ fontWeight: 'bold', marginBottom: 20 }} align="middle">{t("IOAI_Registration_Card_3_Title")}</h3>} bordered={false}>
									<Card type="inner" title={<h5>{t("IOAI_Registration_Card_3_inner_1_Title")}</h5>}>
										<p>{t("IOAI_Registration_Card_3_inner_1_Content1")}</p>
									</Card>
									<Card type="inner" title={<h5>{t("IOAI_Registration_Card_3_inner_2_Title")}</h5>}>
										<p>{t("IOAI_Registration_Card_3_inner_2_Content1")}</p>
									</Card>
									<Card type="inner" title={<h5>{t("IOAI_Registration_Card_3_inner_3_Title")}</h5>}>
										<p>{t("IOAI_Registration_Card_3_inner_3_Content1")}</p>
									</Card>
								</Card>
							</Col>
							<Col md={10} xs={24} align="center">
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={peopleImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(IOAIApplication);
