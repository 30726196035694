/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class VideosContent extends Component {
	componentDidMount() {

	}
	render() {
		const { t } = this.props;
		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/FG_DyRS7Ogw"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video0")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/bUDYF0nT_4g"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video1")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/rko3lOWh6rY"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video2")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/IzucDoTH-Ec"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video3")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/I-_X2t2hMEw"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video4")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/vE84mCxdvqk"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video5")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/Oi1pMKO-cwM"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video6")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/iOP3SXDnwwo"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video7")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/xxf4K1X_838"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video8")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/DFdn1u8SHXs"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video9")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/fNoRQlWcHoY"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video10")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/0FnpkEHsf2Q"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video11")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/ho3N9rkMz64"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video12")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/h-2EYS4m66U"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video13")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/sdGNZ1GbyMY"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video14")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/EdpWmtI6Jew"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video15")}</h6>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 mb-4">
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/X3aqXsFcDu0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<h6>{t("Videos_Description_Video16")}</h6>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(VideosContent);
