/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";

import courseIntroductionPic from '../../images/event/courseintroduction.jpg';
import rafflePic from '../../images/event/raffle.jpg';
import webDev1 from '../../images/event/web1.jpg';
import webDev2 from '../../images/event/web2.jpg';

import { withTranslation } from "react-i18next";


const videoList = [
	{
		title: "Video_Title_Hungry_Snake",
		src: "https://www.youtube.com/embed/BJF_Endku28",
		description: "Video_Description_Hungry_Snake"
	}, 
	{
		title: "Video_Title_3D",
		src: "https://www.youtube.com/embed/VaLj_HzUHD0",
		description: "Video_Description_3D"
	}, 
	{
		title: "Video_Title_Periodic_Table",
		src: "https://www.youtube.com/embed/VkJfuqjIymQ",
		description: "Video_Description_Periodic_Table"
	},
];

class AwardCeremonyContent extends Component {
	componentDidMount() {
		
	}
	render() {
		const { t } = this.props;
		return (
			<>
			{/* course introduction */}
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<h4>{t("Video_Title_Intro_Video")}</h4>
							<iframe
								width="320"
								height="200"
								src="https://www.youtube.com/embed/rko3lOWh6rY"
								title=""
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ margin: 'auto' }}>{t("Video_Description_Intro_Video")}</p>
						</div>
					</div>
				{/* end of course introduction */}

				{/* course videos */}
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12" >
							<h4>{t("Video_Title_Intro_Slide")}</h4>
							<div width="320">
								<a rel="noopener" href="/static/ppt/LeadingCoding.pptx">
									<img alt="" width="320" height="200" src={courseIntroductionPic} />
								</a><br /><br />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ margin: 'auto' }}>{t("Video_Description_Intro_Slide")}</p>
						</div>
					</div>
					<div>
						{videoList.map((item, index) => (
							<div className="row" key={index}>
								<div className="col-lg-6 col-md-12 col-sm-12">
									<h4>{t(item.title)}</h4>
									<iframe
										width="320"
										height="200"
										src={item.src}
										title={item.title}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe><br /><br />
								</div>
								<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
									<p style={{ margin: 'auto' }}>{t(item.description)}</p>
								</div>
								
							</div>
						))}
					</div>
				{/* end of course videos */}

				{/* Web Development course */}
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<h4>{t("Video_Title_Raffle")}</h4>
							<a rel="noopener" href="/raffle">
								<img alt="" width="320" height="200" src={rafflePic} />
							</a><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ margin: 'auto' }}>{t("Video_Description_Raffle")}</p>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<h4>{t("Video_Title_WebDev1")}</h4>
							<a
							href={"/mason"}
							title={t("Video_Title_WebDev1")}
							>
							<img
								src={webDev1}
								alt={t("Video_Title_WebDev1")}
								width="320"
								height="200"
							/>
							</a><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ margin: 'auto' }}>{t("Video_WebDev_Description1")}</p>
						</div>
					</div> 
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<h4>{t("Video_Title_WebDev2")}</h4>
							<a
							href={"/florence"}
							title={t("Video_Title_WebDev2")}
							>
							<img
								src={webDev2}
								alt={t("Video_Title_WebDev2")}
								width="320"
								height="200"
							/>
							</a><br /><br />
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 mb-4" style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ margin: 'auto' }}>{t("Video_WebDev_Description2")}</p>
						</div>
					</div> 
				{/* End of web Development course */}
				</div>
			</>
		);
	}
}

export default withTranslation()(AwardCeremonyContent);
